html { // HTML




// --- gray scale -- //

$color-white: #fff;
$color-black: #000;
$color-clear: rgba(0,0,0,.01);
$color-gray1: #EAF1F4;
$color-gray2: #E1EAEF;
$color-gray3: #BCC8CE;
$color-gray4: #9CAFB9;
$color-gray5: #748995;
$color-gray6: #516874;
$color-gray7: #374D58;
$color-gray8: #203139;
$color-gray9: #101828;

// color (text)
.is-color-white {color: $color-white;}
.is-color-black {color: $color-black;}
.is-color-clear {color: $color-clear;}
.is-color-gray1 {color: $color-gray1;}
.is-color-gray2 {color: $color-gray2;}
.is-color-gray3 {color: $color-gray3;}
.is-color-gray4 {color: $color-gray4;}
.is-color-gray5 {color: $color-gray5;}
.is-color-gray6 {color: $color-gray6;}
.is-color-gray7 {color: $color-gray7;}
.is-color-gray8 {color: $color-gray8;}
.is-color-gray9 {color: $color-gray9;}

// bg
.is-bg-white {background-color: $color-white;}
.is-bg-black {background-color: $color-black;}
.is-bg-clear {background-color: $color-clear;}
.is-bg-gray1 {background-color: $color-gray1;}
.is-bg-gray2 {background-color: $color-gray2;}
.is-bg-gray3 {background-color: $color-gray3;}
.is-bg-gray4 {background-color: $color-gray4;}
.is-bg-gray5 {background-color: $color-gray5;}
.is-bg-gray6 {background-color: $color-gray6;}
.is-bg-gray7 {background-color: $color-gray7;}
.is-bg-gray8 {background-color: $color-gray8;}
.is-bg-gray9 {background-color: $color-gray9;}

// stroke
.is-stroke-white {stroke: $color-white;}
.is-stroke-black {stroke: $color-black;}
.is-stroke-clear {stroke: $color-clear;}
.is-stroke-gray1 {stroke: $color-gray1;}
.is-stroke-gray2 {stroke: $color-gray2;}
.is-stroke-gray3 {stroke: $color-gray3;}
.is-stroke-gray4 {stroke: $color-gray4;}
.is-stroke-gray5 {stroke: $color-gray5;}
.is-stroke-gray6 {stroke: $color-gray6;}
.is-stroke-gray7 {stroke: $color-gray7;}
.is-stroke-gray8 {stroke: $color-gray8;}
.is-stroke-gray9 {stroke: $color-gray9;}




// --- primary -- //

$color-primary6: #0093E8;

// color (text)
.is-color-primary6 {color: $color-primary6;}

// bg
.is-bg-primary6 {background-color: $color-primary6;}

// stroke
.is-stroke-primary6 {stroke: $color-primary6;}



    
    
    
    
} // HTML
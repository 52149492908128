html {

    #lds-body-dashboard3 {
        background-color: #F5F9FD;
    }

    #lds-dashboard3 {
        display: flex;
        align-items: flex-start;
        padding: 24px;

        &-left {
            padding-right: 24px;
        }

        &-right {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;
        }
    }

    #lds-dashboard-sidenav2 {
        background: repeat-x url(../../images/dashboard/dashboard3-sidenav.png); 
        background-size: 336px 936px; 
        width: 336px;  // was 830
        height: 936px;
    }

    #lds-dashboard3-bottom-box {
        position: relative;
        top: 0;
        left: 0;
        height: 505px;
        padding-bottom: 16px;
    }

    #lds-dashboard4-bottom-box {
        position: relative;
        top: 0;
        left: 0;
        height: 505px;
        padding-bottom: 16px;
    }


    // #lds-dashboard3 {
    //     // border: red 1px solid;
    //     padding: 24px;

    //     &-sidenav {
    //         background: repeat-x url(../../images/dashboard/dashboard3-sidenav.png); 
    //         background-size: 336px 936px; 
    //         width: 336px;  // was 830
    //         height: 936px;
    //     }

    //     .lds-layout-sidenav-outer,
    //     .lds-layout-sidenav {
    //         max-width: 359px !important;
    //         min-width: 383px !important;
    //     }
    //     .lds-layout-sidenav {
    //         padding: 24px !important;
    //     }
    // }

    #lds-dashboard2 {
        display: flex;
        // flex-direction: column;
        // gap: 16px;
        // justify-content: flex-start;
        // justify-content: space-between;
        padding: 32px;
        gap: 24px;
        

        &-left {
            width: 100%;

            > * {
                margin-bottom: 16px;
            }
        }
        &-right {
            position: relative;
            top: 0;
            left: 0;
            // background: -1079px -94px url(../../images/dashboard/dashboard.png); 
            // background-size: 1440px 1091px; 
            background: no-repeat url(../../images/dashboard/dashboard-right.png);
            background-size: 335px 980px;
            width: 340px;
            min-width: 340px;
            height: 980px;
        }

        &-bottom {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            &-box {
                position: relative;
                top: 0;
                left: 0;
                width: 50%;
                height: 518px;
            }
        }
    }

    // --- dashboard tasks --- //

    #lds-dashboard-alert {
        position: relative;
        background-color: #FFF7DB;


        .lds-spriteleft,
        .lds-spriteright {
            background: 0 0 repeat-x url(../../images/dashboard/dashboard-alert.png); 
            background-size: 1033px 90px; 
            width: 800px; 
            height: 90px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 130px;
            min-width: 130px; // make it stick
        }
    }

    #lds-dashboard-tasks {
        position: relative;
        background: 0 36px white repeat-x url(../../images/general/table-bg.png); 
        background-size: 100px 1276px; 
        width: 100%; 
        height: 333px;

        .lds-spriteleft,
        .lds-spriteright {
            background: 0 0 repeat-x url(../../images/dashboard/dashboard-tasks2.png); 
            background-size: 1033px 333px; 
            width: 760px;  // was 830
            height: 333px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 170px;
            min-width: 170px; // make it stick
        }
    }

    #lds-dashboard-messages,
    #lds-dashboard-forms {
        background: 0 48px white repeat-x url(../../images/dashboard/dashboard-messages-bg.png); 
        background-size: 200px 400px; 
        width: 100%; 
        height: 488px;

        .lds-spriteleft,
        .lds-spriteright {
            background: 0 0 repeat-x url(../../images/dashboard/dashboard-messages2.png); 
            background-size: 505px 500px; 
            width: 405px; // was 460
            height: 500px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 40px;
            min-width: 40px; // make it stick
        }
    }

    #lds-dashboard-forms {
        background: 0 53px white repeat-x url(../../images/general/table-bg.png); 
        background-size: 100px 1276px; 

        .lds-spriteleft,
        .lds-spriteright {
            background-image: url(../../images/dashboard/dashboard-forms2.png); 
            width: 340px; 
        }
        .lds-spriteright {
            width: 120px;
            min-width: 120px; // make it stick
        }
    }


    // #lds-dashboard-tasks-left,
    // #lds-dashboard-tasks-right {
    //     background: 0 0 repeat-x url(../../images/dashboard/dashboard-tasks.png); 
    //     background-size: 1033px 333px; 
    //     width: 830px; 
    //     height: 333px;
    // }

    // #lds-dashboard-tasks-right {
    //     background-position: top right;
    //     width: 170px;
    //     min-width: 170px; // make it stick
    // }


} // HTML
html {




    .lds-sprite {
        position: relative;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;

        > * {
            position: absolute !important;
            top: 0;
            left: 0;
        }


        &.is-debug {
            border: red 1px solid !important;
        }

        &.is-abs {
            position: absolute !important;
        }

        // types
        &.is-type-input {
            width: 100px;
            height: 30px;
            border-width: 0;

            &:focus {outline: none;}
        }
    }

    // base
    .lds-spritebase {
        
        // modifiers
        &.is-debug {
            border: red 1px solid !important;
        }
    }

    // container 
    .lds-spritecontainer {
        display: block;
        position: relative;
        top: 0;
        left: 0;

        > * {
            position: absolute !important;
            top: 0;
            left: 0;
        }
    }

    // rows 
    .lds-spriterows {
        width: 100%;
        // border: red 1px solid;
    }

    // row 
    .lds-spriterow.lds-spritebutton {
        display: block;
        top: 0;
        left: 0px;
        width: 100%;
        height: 44px;
        // transition: background-color .4s ease-out;

        // &.is-gray {
        //     background-color: rgba(0, 151, 255, 0.08);
        // }

        // &:hover {
        //     background-color: rgba(0, 0, 0, 0.01);

        //     &.is-gray {
        //         background-color: rgba(0, 0, 0, 0.05);
        //     }
        // }

        &.is-selected {
            background-color: rgba(0, 151, 255, 0.11);

            &.is-alt {
                background-color: rgba(0, 151, 255, 0.07);
            }
        }
        // position: relative;
        // top: 0;
        // left: 0;

        // > * {
        //     position: absolute !important;
        //     top: 0;
        //     left: 0;
        // }
    }

    // sidenav 
    .lds-spritesidenav {
        display: block;
        position: fixed;
        z-index: 1;
        top: 72px;
        left: 0;
        width: 270px;
        height: 400px;
        overflow: hidden;

        > * {
            position: absolute !important;
            top: 0;
            left: 0;
        }

        // cta
        .lds-spritebutton.is-sidenav {
            top: 24px;
            left: 20px;
            width: 230px;
        }
    }

    // button
    .lds-spritebutton {
        position: relative;
        width: 120px;
        height: 44px;
        cursor: pointer;
    }

    // liketext
    .lds-spriteliketext {
        width: 120px;
        height: 44px;
        cursor: text;
    }

    // input
    .lds-spriteinput {
        width: 120px;
        height: 34px;
        border-width: 0;
        outline: none;

        /* Text md/Regular */
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        &::placeholder {
            color: #748995;
        }
    }

    // text
    .lds-spritetext {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: white;
        padding: 2px 4px;
    }

    // wrapper 
    .lds-spritewrapper {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
    }

    // sprite select
    .lds-spritewrapper .lds-select {
        width: 100%;
        height: 34px;
        padding-left: 6px;
    }

    // sprite select
    .lds-spritewrapper.is-type-checkbox {
        display: flex;
        align-items: center;
        padding: 2px;
        // background-color: white;
        
        .lds-checkbox {
            width: 100%;

            .lds-icon {
                outline: 2px solid white;
                border-radius: 4px;
            }
        }
    }

    // wrapper 
    .lds-spritesplit222 {
        // display: block;
        width: 100%;

        td {
            width: 50%;
        }
    }

    .lds-spritesplit {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }



    .lds-spritelisttop td {
        position: relative;
        
        
        &.lds-spriteleft {
            background-position: top left;
        }
        
        &.lds-spritemiddle {
            width: auto;
            background-image: none;
        }
        
        &.lds-spriteright {
            background-position: top right;
            padding: 20px 10px 0 0;
            text-align: right;
        }
    }


    // --- advanced --- //

    .lds-sprite-keyboard {
        background: no-repeat url(../../images/app/ui/keyboard.png); 
        background-size: 375px 539px; 
        width: 375px; 
        height: 291px;

        // types\
        &.is-type-numpad {
            width: 375px; 
            height: 248px;
        }
    }




} // HTML
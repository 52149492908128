html {

    // override
    .MuiDialog-paper {
        max-width: 800px;
    }
    .MuiDialog-root .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.3);
    }
    
    .lds-modal {
        // min-height: 400px; // causeing padding issues at bottom
        height: auto; 
        padding: 16px;
        width: 600px; // default
        border-radius: 8px;

        // modifiers
        &.is-size-sm {width: 400px;}
        &.is-size-lg {width: 800px;}

        // members
        &-top {
            // padding-bottom: 16px;
            height: 22px; // 38px;
            width: 100%;
            // fonts
            color: #101828;
            font-size: 18px;
            font-family: Lato;
            font-weight: 800;
            line-height: 20px;
            letter-spacing: 0.54px;

            // outer
            &-outer {
                padding: 16px !important;
            }
        }
        // MuiDialogContent-root lds-modal-content-outer css-ypiqx9-MuiDialogContent-root
        &-content  {
            .lds-alert {
                margin-bottom: 30px;
            }

            // outer
            &-outer {
                padding: 0 16px !important;
            }
        }

        &-bottom {
            // padding-top: 16px;
            padding: 8px;
            width: 100%;
            text-align: right;
        }
        &-closer {
            position: absolute;
            top: 16px;
            left: auto;
            right: 16px;
            width: 22px;
            height: 22px;
            cursor: pointer;
        }
    }

    .testing222,
    #scroll-dialog-title {
        border: red 2px solid !important;
    }


} // HTML
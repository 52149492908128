html {

    .lds-mobile {

        &-global-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            &-left {
                position: absolute;
                top: 0;
                left: 20px;
                // border: red 1px solid;
                margin-top: 24px;
                // border: red 1px solid;
                
                a {
                    display: block;
                    color: #ccc;
                    padding: 6px 24px;
                    text-decoration: none;
                    &:hover {
                        color: #fff;
                    }
                }
            
                &-actions {
                    background: url(../../images/app/ui/mobile-buttons.png); // toggle-desktop
                    background-size: 126px 55px; 
                    top: 20px;
                    width: 66px; 
                    height: 55px;
                    // border: blue 1px solid;
                }
            
            }
        }

                  // &-actions {
            //     background: url(../../images/app/ui/mobile-buttons.png); // toggle-desktop
            //     background-size: 126px 55px; 
            //     top: 20px;
            //     width: 126px; 
            //     height: 55px;
            //     border: red 1px solid;
            // }  

        &-phone {
            position: relative;
            top: 0;
            left: 0;
            width: 512px;
            height: 870px;
            background: url(../../images/general/iphone.png);
            background-size: 512px 933px;
            background-position: 0 -28px;
            
            // notch
            &-notch {
                position: absolute;
                top: 28px;
                left: 165px;
                z-index: 10000;
                background: url(../../images/app/ui/phone-notch.png);
                background-size: 173px 32px;
                width: 173px;
                height: 32px;
            }

            &-mic {
                position: absolute;
                top: auto;
                bottom: 38px;
                left: 181px;
                z-index: 10000;
                width: 140px;
                height: 5px;
                border-radius: 100px;
                background-color: black;
            }
        }

        // wrapper / content
        &-wrapper {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding-top: 147px;
            padding-bottom: 90px;

            &.is-noscrolling .lds-mobile-scroll {
                overflow-y: hidden;
            }
        }

        &-header {
            position: absolute;
            top: 0;
            left: 0;
            height: 100px;
            width: 100%;
            z-index: 1000;
            background-color: white;
            width: 100%;
            height: 100px;

            // home
            background: url(../../images/app/ui/header-home.png);
            background-size: 376px 148px;
            width: 100%;
            height: 148px;

            b {
                position: absolute;
                left: 16px;
                top: auto;
                bottom: 11px;
                font-family: lato;
                color: white;
                font-size: 32px;
                font-weight: 500;
            }
        }

        &-content {
            position: absolute;
            top: 28px;
            left: 63px;
            width: 375px;
            height: 812px;
            background-color: white;
            border-radius: 42px;
            overflow: hidden;

            // .MuiModal-root {
            //     position: absolute;
            // }

            // .MuiBackdrop-root {
            //     position: absolute;
            //     background-color: white !important; // rgba(255, 0, 0, 1)
            // }
        }

        &-scroll {
            position: relative; // absolute;
            top: 0; // 147px
            left: 0;
            width: 100%;
            height: 100%; //  575px; // 100%;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 1;
        }

        // toggle buttons
        &-button,
        &-button-desktop {
            position: fixed;
            bottom: 20px;
            right: 20px;
            // width: 50px;
            // height: 50px;
            // border-radius: 100px;
            // background-color: white;
            // box-shadow: 0px 9px 12px 0px rgba(0, 0, 0, 0.25);
            // z-index: 100;
            background: url(../../images/general/toggle-mobile.png);
            background-size: 72px 114px; 
            width: 72px; 
            height: 114px;
            z-index: 100;
            cursor: pointer;
        }

        &-button-desktop {
            background: url(../../images/general/toggle-x.png); // toggle-desktop
            background-size: 146px 112px; 
            top: 20px;
            width: 146px; 
            height: 112px;
        }

        &-fade {
            background-color: #555555; // rgba(85, 85, 85, 0.5);
        }

    }

    // basic wrapper
    .lds-mobile-wrapper.is-type-basic {
        padding-top: 0;
        padding-bottom: 0;

        // .lds-mobile-header {
        //     background: url(../../images/app/ui/header-tab.png);
        //     background-size: 376px 109px;
        //     height: 109px;

        //     b {
        //         bottom: 14px;
        //         font-size: 24px;
        //         font-weight: 500;
        //     }
        // }
    }

    // tab wrapper
    .lds-mobile-wrapper.is-type-tab {
        padding-top: 108px;

        &.is-name-meds {
            padding-top: 168px;
        }

        .lds-mobile-header {
            background: url(../../images/app/ui/header-tab.png);
            background-size: 376px 109px;
            height: 109px;

            b {
                bottom: 14px;
                font-size: 24px;
                font-weight: 500;
            }
        }
    }


    // sub wrapper
    .lds-mobile-wrapper.is-type-sub {
        padding-top: 88px;
        padding-bottom: 0;

        &.is-name-meds {
            padding-top: 168px;
        }

        .lds-mobile-header {
            background: url(../../images/app/ui/header-sub.png);
            background-size: 376px 88px;
            height: 88px;

            b {
                display: block;
                width: 300px;
                left: 40px;
                bottom: 9px;
                font-size: 18px;
                line-height: 28px;
                font-weight: 500;
                // background-color: gray;
                text-align: center;
                cursor: default;
            }
        }
    }


    // modal (and related)
    .lds-mobile-modal {
        
        position: absolute;
        top: 0;
        left: 0;
        background: white no-repeat url(../../images/app/ui/header-modal.png);
        background-size: 375px 61px;
        width: 100%;
        height: 100%;
        margin: 0 !important;

        // modifier
        &.is-noscroll .lds-mobile-modal-content {
            overflow-y: hidden;
        }

        // &.is-bottomsheet {
        //     background: transparent no-repeat url(../../images/app/ui/header-sheet-modal.png);
        // }
        
        // transform: mo;
        // border: blue 2px solid; // KEEP

        &-header {
            // border: red 1px solid;
            height: 92px;
            text-align: center;
            width: 100%;
            padding: 58px 50px 8px;

            &-verytop {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 44px;
                // border: blue 2px solid; // KEEP
            }

            &-left,
            &-right {
                position: absolute;
                top: 60px;
                left: 10px;
                width: 30px;
                height: 30px;
            }

            &-right {
                left: auto;
                right: 10px;
            }





            b {
                display: block;
                // width: 300px;
                // border: blue 1px solid;
                font: 500 18px/28px Lato;
                color: #101828;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: default;
            }
        }

        &-content {
            position: relative;
            top: 0;
            left: 0;
            height: 720px;
            overflow-x: hidden;
            overflow-y: auto;
            // border: yellow 10px solid;
        }

        &-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            border-top: #eee 1px solid;
            // border: blue 2px solid; // KEEP
        }
    }

    #lds-mobile-content {

        .MuiDialog-container {
            // background: no-repeat url(../../images/app/ui/header-modal.png);
            // background-size: 375px 61px;
            background-color: black;
            display: block;
            margin: 0;
            transition: opacity .5s cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
            // border: red 2px solid; // KEEP
        }

        .MuiDialog-paper {
            // display: none !important; // TEMP
            // position: relative;
            // top: 61px;
            transition: box-shadow .5s cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
            overflow: hidden;
            height: 100%;
            margin: 0 !important;
            box-shadow: none;
            max-height: 100%;
            // border: yellow 2px solid; // KEEP

            border-radius: 40px; // --- WIP
        }

        .MuiModal-root {
            position: absolute;
        }
        .MuiBackdrop-root {
            // background-color: white !important;
            position: absolute; // override, was "fixed"
            // transform: opacity 2s cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
        }
    }

        // &-wrapper {
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     width: 100%;
        //     height: 100%;
        // }



    // override
    // .MuiDialog-paper {
    //     max-width: 800px;
    // }
    // .MuiDialog-root .MuiBackdrop-root {
    //     background-color: rgba(0, 0, 0, 0.3);
    // }
    
    // .lds-mobile {
    //    //  border: red 2px solid !important;



    // .lds-home-wrapper {
    //     height: 100%;;

    //     .lds-mobile-scroll {
    //         height: 100%;
       
    //     }
    // }

    // // --- headers --- //

    // .lds-mobile-header {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     height: 100px;
    //     width: 100%;
    //     z-index: 1000;
    //     background-color: white;
    //     width: 100%;
    //     height: 100px;

    //     // modifier
    //     &.is-type-home {
    //         background: url(../../images/app/ui/header-home.png);
    //         background-size: 376px 148px;
    //         width: 100%;
    //         height: 148px;
    //     }
    // }

} // HTML


// .lds-mobile-modal.is-bottomsheet 
// .lds-mobile-modal.is-bottomsheet
html .lds-mobile-phone #lds-mobile-content .MuiDialog-container {
    background-color: transparent !important;
}

html .lds-mobile-phone .MuiPaper-root {
    background-color: transparent !important;
}

// html .lds-mobile-modal
html .lds-mobile-modal.is-bottomsheet {

    // background: transparent no-repeat url(../../images/app/ui/header-sheet-modal.png);
    background-color: transparent !important;
    background-image: none !important;
    // background-image: url(../../images/app/ui/header-sheet-modal.png) !important; // --- WIP
    // background-position: left top;
}

html .lds-mobile-modal.is-bottomsheet .lds-mobile-modal-header {
    background: transparent no-repeat url(../../images/app/ui/header-sheet-modal.png);
    background-size: 375px 61px;
    background-position: 0 42px;
}   
/*

html .lds-mobile-modal {
    background: // --- WIP
}

html .lds-mobile-modal-header {
    margin-top: 100px; // --- WIP
}
*/
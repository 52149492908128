html {


    #lds-auth-login {
        background: no-repeat url(../../images/auth/auth-login.png);
        background-size: 350px 314px; 
        width: 350px; 
        height: 314px;
    }

    #lds-login-form {
        position: relative;
        top: 0;
        left: 0;
        width: 350px;

        > * {
            margin-bottom: 24px;
        }

        .lds-input.is-error { // NOTE: this is a hack, need better way
            margin-bottom: 44px;
        }

        &-title {
            color: var(--gray-900, #101828);
            font-size: 32px;
            font-family: Lato;
            line-height: 34px;
        }

        &-forgot {
            color: var(--primary-600, #0093E8);
            font-size: 16px;
            font-family: Lato;
            line-height: 24px;
            text-decoration: none;
        }
    }



} // html
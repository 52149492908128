html {

    // loader
    .lds-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba(255, 255, 255, 0.5) !important;
        /// transition-duration: .3s !important; // TEMP


        // spinning circle
        .MuiCircularProgress-svg {
            // color: #27A4ED;
            color: #0075b9;
        }

        &.is-color-ltgray {
            background-color: #F5F9FD;
        }

    }
    
    // loader background
    .lds-loader-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 1;

        &.is-color-ltgray {
            background-color: #F5F9FD !important;
        }
    }

    // color
    .lds-loader.is-color-clear,
    .lds-loader.is-color-translucent {
        background-color: transparent !important;

        .MuiCircularProgress-root {
            background-color: white !important;
            padding: 10px;
            border-radius: 100px;
            width: 50px !important;
            height: 50px !important;
        }
        // MuiCircularProgress-root MuiCircularProgress-indeterminate MuiCircularProgress-colorInherit css-62e83j-MuiCircularProgress-root
    }

    .lds-loader.is-color-translucent {
        background-color: rgba(255, 255, 255, 0.5) !important;
    }

    .lds-loader.is-color-inverse {
        background-color: rgba(255, 255, 255, 0.01) !important;
        .MuiCircularProgress-svg {
            color: white;
        }
    }

    .lds-loader-bg.is-color-translucent {
        background-color: rgba(255, 255, 255, 0.5) !important;

    }


    // MuiBackdrop-root lds-loader is-type-clear is-debug css-1ji7omv-MuiBackdrop-root



} // END HTML
html {

    // split
    #lds-forms-subs {
        background: 0 72px white repeat-x url(../../images/general/table-bg.png); 
        background-size: 100px 1276px; 
        width: 100%; 
        height: 380px;

        .lds-spriteleft,
        .lds-spriteright {
            background: 0 -72px url(../../images/forms/forms-subs2.png);
            background-size: 1440px 1024px; 
            width: 820px; 
            height: 380px;
        }
        .lds-spriteright {
            background-position: -1120px -72px;
            width: 200px;
            min-width: 200px; // make it stick
        }
    }


    #lds-forms-details {
        position: relative;
        background: repeat-x url(../../images/general/black-line.png);
        background-size: 100px 100px; 
        background-position: 0px -23px;

        .lds-spriteleft,
        .lds-spriteright {
            background: no-repeat url(../../images/forms/forms-details.png);
            background-size: 1122px 1941px; 
            width: 847px; 
            height: 2041px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 270px;
            min-width: 270px; // make it stick
        }
    }

    // --- new

    #lds-forms-sidenav {
        // background: 0 -72px url(../../images/forms/forms-subs2.png);
        background: no-repeat url(../../images/forms/forms-sidenav.png);
        background-size: 270px 400px; 
        width: 270px; 
        height: 400px;
    }

    #lds-forms-submissions {
        background: 0 72px white repeat-x url(../../images/general/table-bg.png); 
        background-size: 100px 1276px; 
        width: 100%; 
        height: 336px;
        margin-bottom: 100px;

        .lds-spriteleft,
        .lds-spriteright {
            background: no-repeat url(../../images/forms/forms-submissions.png);
            background-size: 1000px 336px; 
            width: 530px; 
            height: 336px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 170px;
            min-width: 170px; // make it stick
        }
    }

    #lds-forms-forms {
        background: repeat-x url(../../images/forms/forms-forms-bg.png); 
        background-position: 0 0;
        background-size: 20px 692px; 
        width: 100%; 
        height: 692px;
        margin-bottom: 100px;

        .lds-spriteleft,
        .lds-spriteright {
            background: no-repeat url(../../images/forms/forms-forms.png);
            background-size: 1000px 692px; 
            width: 620px; 
            height: 692px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 230px;
            min-width: 230px; // make it stick
        }
    }

    #lds-forms-editor {
        background: repeat-x url(../../images/forms/forms-editor-bg.png); 
        background-position: 0 0;
        background-size: 20px 1242px; 
        width: 100%; 
        height: 1242px;
        margin-bottom: 100px;

        .lds-spriteleft,
        .lds-spriteright {
            background: no-repeat url(../../images/forms/forms-editor.png);
            background-size: 1122px 1242px; 
            width: 440px; 
            height: 1242px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 410px;
            min-width: 410px; // make it stick
        }
    }

} // html
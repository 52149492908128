html {

    // general

    #lds-demos-page222 {

    }

    #lds-demos-page {

        h1 {
            font: 600 normal 24px/26px Lato;
            color: #101828;
            margin-bottom: 4px;
        }
    
        h2 {
            font: 600 20px/22px Lato;
            color: #101828;
            margin-bottom: 8px;
        }
    
        p {
            font: 600 14px/16px Lato;
            color: #748995;
            margin: 0 0 24px;
        }
    }

    .lds-demos {


        &-link {
            padding: 4px 0 8px;
        }
    }



    // hide topnav
    #lds-body-demos-onboard1 .lds-topnav {
        display: none;
    }
    
    // components
    #lds-demos-page {
        padding: 16px 24px;
    }


    #lds-demos-list {
        min-height: 400px;

        > div {
            color: #0093E8;
            cursor: pointer;
            padding-bottom: 12px;
        }
    }


} // html